import {ActiveLoader, useLoading} from "vue-loading-overlay";
import {Ref} from "vue";

const currentLoader: Ref<ActiveLoader|null> = ref(null);

export function useLoader() {

  const loader = useLoading({
    isFullPage: false,
    loader: 'spinner',
    color: '#0C98DE'
  });

  function showLoader(container: any|null) {
    currentLoader.value = loader.show({
      container: container,
    });
  }

  function hideLoader() {
    if (currentLoader.value) {
      currentLoader.value.hide();
      currentLoader.value = null;
    }
  }

  return {
    showLoader,
    hideLoader,
  }
}
